import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import SubSubTitle from './Titles/SubSubTitle'

const PublicationCard = ({ content: pub, lang }) => {
  const image =
    pub.image && pub.image.localFile ? (
      <GatsbyImage
        image={getImage(pub.image.localFile.childImageSharp)}
        className="object-cover w-full h-full"
        alt="Publication image"
      />
    ) : null

  const link = lang === 'de' ? '/de' : ''

  return (
    <section key={pub.slug}>
      <Link
        to={link + '/publications/' + pub.slug}
        className={`relative flex flex-col sm:flex-row bg-gray-100 h-auto my-2 no-underline`}
      >
        {pub.image && (
          <div className="inline-block w-full sm:w-[200px] h-[200px] sm:h-auto bg-gray">
            {image}
          </div>
        )}
        <div className="flex-1 p-5 font-text">
          <SubSubTitle className="mr-4">
            {pub.title} ({new Date(pub.publication_date).getFullYear()})
          </SubSubTitle>
          <span className="block mb-3 text-sm text-gray-600">
            {pub.publisher}
          </span>
          <div className="flex flex-wrap items-center">
            <span className="inline-flex px-3 py-1 mt-2 mr-2 text-xs font-medium tracking-wide text-white uppercase bg-gray-500 rounded-sm font-text">
              {pub.type.replace('_', ' ')}
            </span>

            {pub.thesis === 'bachelor' && (
              <span className="inline-flex px-3 py-1 mt-2 mr-2 text-xs font-medium tracking-wide text-white uppercase bg-red-500 rounded-sm font-text">
                Bachelor Thesis
              </span>
            )}

            {pub.thesis === 'master' && (
              <span className="inline-flex px-3 py-1 mt-2 mr-2 text-xs font-medium tracking-wide text-white uppercase bg-red-500 rounded-sm font-text">
                Master Thesis
              </span>
            )}

            {pub.honorable_mention && (
              <span className="inline-flex px-3 py-1 mt-2 mr-2 text-xs font-medium tracking-wide text-black uppercase bg-yellow-500 rounded-sm font-text">
                Honorable Mention
              </span>
            )}

            {pub.best_paper && (
              <span className="inline-flex px-3 py-1 mt-2 mr-2 text-xs font-medium tracking-wide text-black uppercase bg-yellow-500 rounded-sm font-text">
                Best Paper
              </span>
            )}

            {pub.best_student_paper && (
              <span className="inline-flex px-3 py-1 mt-2 mr-2 text-xs font-medium tracking-wide text-black uppercase bg-yellow-500 rounded-sm font-text">
                Best Student Paper
              </span>
            )}

            {pub.reviewers_choice && (
              <span className="inline-flex px-3 py-1 mt-2 mr-2 text-xs font-medium tracking-wide text-black uppercase bg-yellow-500 rounded-sm font-text">
                Reviewers Choice Award
              </span>
            )}
          </div>
        </div>
      </Link>
    </section>
  )
}

PublicationCard.propTypes = {
  content: PropTypes.object,
}

export default PublicationCard
